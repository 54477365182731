import footer_images from "./constant_datas/footer_images";
import {pages, btn} from "./languages/buttons";
import {languagesPath as lng} from "./languages/langOptions/languages";

const casino = {
    name: "Slot Powers",
    rival: {
        id: 28,
        sortName: "absoluteslots",
        chatName: "Slot Powers",
    },
    GA_TRACKING:'G-FR7EHJT1SX',
    support_email: "support@slotpowers.com",
    support_documents: "documents@slotpowers.email",
    url: "slotpowerscasino.com",
    CAF: "https://www.google.com",
    leaderboard: "https://docs.google.com/spreadsheets/d/e/2PACX-1vT2uATYXc0Nsg8GJt7q9c0uiLaCr-eWt2b5d5LE9pJs0jQPI_WXwUFgozEKTsppWqKh0-UYZm9RcN9x/pub?gid=208952145&single=true&output=csv",
    instantPlayRegister: true,
    languages: [lng.en, lng.de, lng.fr, lng.es, lng.it, lng.pt],
    layout: {
        header: {
            burger_menu_color: "#4FD1C5",
            header_pages: [],
            side_bar: {
                someting: [pages.promotions, pages.leaderboard],
                pages: [
                    pages.about,
                    pages.support,
                    pages.faq,
                    pages.aml,],
                games: [
                    pages.banking,
                    pages.bitcoin,
                    pages.cookie_policy,
                ],
            }
        },
        footer: {
            footer_top: [pages.chat, pages.language],
            footer_images: [
                footer_images.EightTeen,
                footer_images.Rival,
                footer_images.Visa,
                footer_images.MasterCard,
                footer_images.Bitcoin,
            ],
            pages: [
                pages.about,
                pages.support,
                pages.faq,
                pages.aml,
                pages.banking,
                pages.bitcoin,
                pages.cookie_policy,
                pages.privacy,
                pages.responsible_gaming,
                pages.security,
                pages.terms,
            ],
            general_info: [pages.about, pages.support, pages.faq],
            casino: [pages.aml, pages.banking, pages.bitcoin],
            security_and_privacy: [
                pages.security,
                pages.cookie_policy,
                pages.privacy,
            ],
            terms_and_conditions: [pages.terms, pages.responsible_gaming,],
            promotions: [pages.promotions, pages.leaderboard],
        },
    },
};

export default casino;


